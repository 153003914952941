@import 'styles/colors';

.modal {
  position: absolute;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  z-index: 1;

  &.modal-open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .modal-backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.75;
    pointer-events: all;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    pointer-events: inherit;
    z-index: 2;
    min-height: 10em;
    border-radius: 8px;
    padding: 16px;

    @media (max-width: 750px) {
      width: 90% !important;
      height: auto !important;
    }
  }
}
