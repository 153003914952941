@import 'styles/colors';
.button {
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin: 0;

  &:hover {
    filter: brightness(90%);
  }

  &.disabled {
    cursor: default;
    filter: opacity(50%);
    pointer-events: none;
  }

  &.standard {
    padding: 0.75em 1em;
  }

  &.thin {
    padding: 0.5em 1em;
  }

  &.primary {
    background-color: $primary-main;
    color: $white;
  }

  &.primary-light {
    background-color: $primary-light;
  }

  &.secondary {
    background-color: $secondary-main;
    color: $white;
  }

  &.accent {
    background-color: $accent-main;
    color: $white;
  }

  &.danger {
    background-color: $red-main;
    color: $white;
  }

  &.warning {
    background-color: $yellow-main;
    color: $white;
  }

  &.grey {
    background-color: $grey-2;
    color: $white;
  }

  &.primary-outline {
    color: $primary-main;
    border: 1px solid $primary-main;
    background-color: $white;
  }

  &.secondary-outline {
    color: $secondary-main;
    border: 1px solid $secondary-main;
    background-color: $white;
  }

  &.accent-outline {
    color: $accent-main;
    border: 1px solid $accent-main;
    background-color: $white;
  }

  &.danger-outline {
    color: $red-main;
    border: 1px solid $red-main;
    background-color: $white;
  }

  &.warning-outline {
    color: $yellow-main;
    border: 1px solid $red-main;
    background-color: $white;
  }

  &.grey-outline {
    color: $grey-1;
    border: 1px solid $grey-1;
    background-color: $white;
  }
}
