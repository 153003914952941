// Primary Colors
$primary-main: #116745;
$primary-dark: #012919;
$primary-light: #c3f3e0;

// Accent
$secondary-main: #ffc4dc;
$secondary-dark: #1b1f3b;
$secondary-light: #b3e3ff;

$red-main: #d7263d;
$red-dark: #58030e;
$red-light: #ed8996;

$yellow-main: #f1e756;
$yellow-dark: #9a7100;
$yellow-light: #fff9c1;

// Accent Colors
$accent-main: #cbc9ad;
$accent-dark: #3b1c00;
$accent-light: #f5f4e8;

// Neutral Colors
$dark-1: #1d1d35;
$grey-5: #f0f0f0;
$grey-4: #c5cecb;
$grey-3: #a3b2b0;
$grey-2: #839798;
$grey-1: #4d4d4d;
$white: #ffffff;
$black: #000000;
$background: #f0f0f0;
$neutral-2: #fffef7;
