.dashboard-item-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  .title {
    padding: 0.25em;
    font-size: 24px;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
    }
    .selection {
      width: 100%;
    }
  }
  .modal-footer {
    margin-left: auto;
  }
}
