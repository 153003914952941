@import 'styles/colors';

.dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 1em;
}
