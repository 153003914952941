@import 'styles/colors';

.account-list-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-4;
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      margin-left: 0.25em;
    }

    .health {
      margin-right: 0.25em;
    }

    .button {
      margin: 0 0.25em;
    }
  }
}

.list-accounts-card {
  .title {
    font-size: 100%;
    margin-bottom: 0.25em;
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list-accounts-empty {
    font-size: 14px;
    margin: 1em 0;
  }
}

.resource-icon {
  font-size: 14px;
  padding: 0.5em;
  border-radius: 5px;
  color: $white;
}

.account-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  width: 50%;
  .organization-select {
    font-size: 14px;
    width: 75%;
  }
}
