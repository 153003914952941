.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;

  .title {
    font-weight: 600;
  }

  .metric-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.25em;
    width: 100%;

    .last-fetched-at {
      font-size: smaller;
      margin-left: 1em;
    }
    .button {
      margin-left: auto;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
