@import 'styles/colors';

.dashboard {
  .dashboard-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .dashboard-title {
      input {
        width: 100%;
        font-size: 22px;
        border-radius: 5px;
      }
    }
  }

  .add-dashboard-item {
    display: flex;
    align-items: center;
  }
}
