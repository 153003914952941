@import 'styles/colors';
.app-layout {
  display: flex;
  flex-direction: row;

  height: 100%;
  width: 100%;

  .content {
    flex-grow: 1;
    margin: 1em;
    overflow-y: auto;
  }
}

.layout {
  height: 100%;
  width: 100%;

  .container {
    .background {
      position: absolute;
      width: 100%;
      z-index: 0;
      img {
        width: 100%;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      position: relative;

      z-index: 1;
    }
  }
}
