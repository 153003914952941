@import 'styles/colors';

.card {
  margin: 0.1em 0.1em;
  background-color: $white;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 1em;
  border: 1px solid $grey-5;
}
