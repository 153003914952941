@import 'styles/colors';

.chart-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .header {
    display: flex;
    flex-direction: row;

    .title {
      color: $dark-1;
      font-size: medium;
    }
    .settings-icon {
      margin-left: auto;
      color: $secondary-dark;
    }
  }

  .item-container {
    display: flex;
    margin: 0.25em 0;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    min-height: 100px;
    max-height: 300px;
  }
  .item-footer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-left: auto;
    }
  }
  .resource {
    color: $grey-2;
    font-size: 12px;
  }
}
