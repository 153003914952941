@import 'styles/colors';

.dashboard-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  margin: 0.1em 0.1em;
  height: 225px;
  background-color: $white;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid $grey-5;

  @media screen and (min-width: 480px) and (max-width: 840px) {
    @for $i from 1 through 12 {
      &.#{width}-#{$i} {
        width: percentage(0.083 * 2 * $i - 0.01);
      }
    }
  }

  @media screen and (min-width: 840px) {
    @for $i from 1 through 12 {
      &.#{width}-#{$i} {
        width: percentage(0.083 * $i - 0.01);
      }
    }
  }

  .chart-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .header {
      display: flex;
      flex-direction: row;

      .title {
        color: $dark-1;
        font-size: medium;
      }
      .settings-icon {
        margin-left: auto;
        color: $secondary-dark;
      }
    }

    .item-container {
      display: flex;
      margin: 0.25em 0;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      min-height: 100px;
      max-height: 300px;
    }
    .item-footer {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        margin-left: auto;
      }
    }
    .resource {
      color: $grey-2;
      font-size: 12px;
    }
  }
}

.dashboard-kpi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .percent-diff-container {
    display: flex;
    justify-content: center;
  }
}
