@import 'styles/colors';

.add-shopify-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 300px;
    max-width: 400px;
    min-height: 550px;

    .title {
      font-size: larger;
      font-weight: 500;
      margin-bottom: 5em;
    }

    .shopname {
      margin-top: auto;
    }

    .submit {
      margin-top: auto;
    }
  }
}
