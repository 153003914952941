@import 'styles/colors';

.entry-list {
  border: solid 1px $grey-3;
  .entry-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0.25em;
    border-bottom: solid 1px $grey-4;

    &:last-child {
      border-bottom: none;
    }
    .values {
      display: flex;
      flex-direction: row;
      .value {
        display: flex;
        flex-direction: row;
        margin: 0 1em;

        .label {
          margin-right: 0.25em;
        }
      }
    }
  }
}
