@import 'styles/colors';

.nav-panel {
  margin: 1em;
  border-right: 1px solid $grey-1;
  height: 100%;
  .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.25em;

    .title {
      margin: 0 0.5em;
    }
  }
}

.settings {
  height: 100%;

  .settings-tabs {
    display: flex;
    flex-direction: row;
    margin: 1em 0;

    .tab {
      margin: 0 1em;
      &.selected {
        background-color: $primary-main;
        color: $white;
        padding: 0.5em;
        border-radius: 1em;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }

  > .title {
    font-size: 200%;

    span {
      color: $primary-main;
    }
  }

  .settings-tab {
    font-size: 150%;
  }
}
