@import 'styles/colors';

.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    margin-left: 0.25em;
    margin-right: 1em;
    font-size: 100%;
  }

  .org-title {
    font-size: 125%;
    font-weight: 500;
  }

  .expand-icon {
    margin-left: auto;
  }

  &.selected {
    background-color: $primary-main;
    color: $white;
  }

  .icon {
    margin-right: 1em;
  }
}
