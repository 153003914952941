@import 'styles/colors';

.tooltip {
  min-width: 150px;
  background-color: rgba($color: $white, $alpha: 0.5);
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25em;
    padding-bottom: 0.25em;
    border-bottom: 1px solid $black;
  }
  .tooltip-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      margin-right: 1em;
    }
  }
}
