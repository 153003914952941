@import 'styles/colors';

body {
  margin: 0;
  width: 100%;
  color: $black;

  &.modal-open {
    height: 100vh;
    overflow-y: hidden;
    position: fixed;

    @media (min-width: 750) {
      position: absolute;
    }
  }
}

html,
body,
#root,
#app,
#app > div,
.Polaris-Frame__Content {
  height: 100%;
  background-color: $background;
}

#modal-root {
  pointer-events: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
