@import 'styles/colors';
.action-bar {
  display: flex;
  flex-direction: column;
  align-items: center;

  .action-bar-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0.5em 0;
  }

  .date-range {
    display: flex;

    align-items: center;
    margin: 0 1em;
    font-size: 16px;
    color: $white;
  }

  .date-options {
    display: flex;
    margin-left: auto;
    .date-bubble {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2em;
      height: 2em;
      cursor: pointer;
      font-size: 12px;
      margin: 0.1em 0.25em;
      padding: 0.1em;
      background-color: $primary-main;
      color: $white;
      border-radius: 50%;

      &.selected {
        color: $black;
        background-color: $primary-light;
      }
    }
  }
}
