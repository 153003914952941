@import 'styles/colors';

.editable {
  display: flex;
  align-items: center;

  .icon {
    color: $grey-1;
  }

  input {
    background-color: transparent;
    border: 0;
    overflow-wrap: break-word;
    word-break: break-word;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid $primary-main;
      background-color: $white;
    }

    &:focus {
      cursor: text;
      background-color: $white;
    }

    &:disabled {
      cursor: default;
    }
  }
}
