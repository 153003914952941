@import 'styles/colors';

.facebook-button {
  width: 50px;
  height: 40px;
  color: $white;
  padding: 10px;
  background-color: #4267b2;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    filter: brightness(90%);
  }
}
