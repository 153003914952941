@import 'styles/colors';

.dashboard-item-card {
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  .section-title {
    font-size: larger;
    font-weight: 500;
    margin: 1em 0;
  }
}

.dashboard-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  margin: 0.1em 0.1em;
  height: 225px;
  background-color: $white;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid $grey-5;

  @media screen and (min-width: 480px) and (max-width: 840px) {
    @for $i from 1 through 12 {
      &.#{width}-#{$i} {
        width: percentage(0.083 * 2 * $i - 0.01);
      }
    }
  }

  @media screen and (min-width: 840px) {
    @for $i from 1 through 12 {
      &.#{width}-#{$i} {
        width: percentage(0.083 * $i - 0.01);
      }
    }
  }
}
