@import 'styles/colors';

.connect-accounts-card {
  .title {
    font-size: 100%;
    margin-bottom: 0.25em;
  }
  .card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.integration-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  border-radius: 5px;
  margin: 0em 0.25em;

  &:first-child {
    margin: 0;
  }
  cursor: pointer;

  .logo {
    padding: 5px 10px;
    margin: 0px 10px;
  }
}

@media screen and (max-width: 990px) {
  .integrations {
    flex-wrap: wrap;
  }

  .integration-card:first-child {
    flex-basis: 100%;
  }
}
