@import 'styles/colors';

.auth-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 400px;
  min-height: 550px;
  margin: 2em;
  padding: 2em;

  .title {
    font-size: 20px;
    margin-bottom: 1em;
  }

  .description {
    color: $primary-dark;
    font-size: medium;
    margin: 1em 0;
  }

  .auth-message {
    text-align: center;
    padding: 0.2em 0.4em;
    border-radius: 5px;
    color: $white;

    &.error {
      background-color: $red-light;
      color: $black;
    }
  }

  .auth-content {
    margin-top: auto;
    width: 100%;
  }

  .auth-input {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 1em 0;

    input {
      width: 100%;
    }
  }

  .auth-terms {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1em;
  }

  .wide-button {
    width: 100%;
  }

  .auth-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2em;
    width: 100%;
    a {
      width: 100%;
      margin: 0.25em 0;
      &:first-child {
        margin-right: 0.25em;
      }

      &:last-child {
        margin-left: 0.25em;
      }
    }
  }
}
