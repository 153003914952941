@import 'styles/colors';

input {
  &.input-text {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $grey-3;
    padding: 8px;
  }
  &:focus {
    border: solid 1px $primary-main;
    outline: none;
  }
  &.error {
    border: 1px solid $red-main;
  }
}

label {
  width: 100%;
  .label-text {
    margin-bottom: 4px;
    color: $grey-1;
  }
}
