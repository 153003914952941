.stripe-manage {
  max-width: 500px;
  .title {
    margin: 0.5em 0;
    font-size: 150%;
  }

  .description {
    font-size: 14px;
    margin: 0.5em 0;
  }

  .manage {
    margin-top: 1em;
  }
}

.checkout-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 0.25em;
  }
}
