@import 'styles/colors';

.navbar {
  display: flex;
  flex-direction: row;
  background-color: $primary-main;
  color: $white;
  padding: 0.5em 0;

  .navbar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navbar-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  }

  .navbar-link {
    margin: 0 0.5em;
    height: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;
    a {
      text-decoration: none;
      color: $white;
    }
    &:hover {
      filter: brightness(90%);
    }
  }
}

.side-panel {
  display: flex;
  max-width: 175px;
  background-color: $primary-main;
  color: $white;
  border-radius: 0 1em 1em 0;
  align-items: center;
  justify-content: center;

  .side-panel-show {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .side-panel-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.25em;
    border-bottom: 1px solid $white;

    .toggle-button {
      margin-left: auto;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .footer {
    margin-top: auto;
    margin-bottom: 0.5em;
  }

  .side-panel-signout {
    display: flex;
    justify-content: center;
    background-color: $accent-main;
    color: $black;
    padding: 0.5em 0;
    border-bottom-right-radius: 1em;
  }

  .side-panel-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5em 0.5em;

    &.disabled {
      cursor: default;
    }

    &.selected {
      background-color: $primary-light;
      color: $black;
    }

    .title {
      display: flex;
      align-items: center;
      margin-left: 0.5em;
    }

    &.signout {
      color: $white;
      background-color: $accent-main;
    }

    &.child {
      justify-content: center;
      font-size: 90%;
    }
  }
}
